<template>
  <div>
    <b-card no-body class="py-2">
      <b-row class="mb-2 mx-1 mt-1">
        <b-col
          offset="8"
          v-if="firstFilter.value == 'report'"
          cols="4"
          class="d-flex align-items-center"
        >
          <b-button
            ref="button_refresh"
            variant="success"
            class="btn-icon mr-1 mt-1 d-flex text-black"
            style="white-space: nowrap"
            @click="refreshTable()"
          >
            <template>
              <feather-icon icon="RefreshCcwIcon" class="mr-50" />
              Refresh
            </template>
          </b-button>
          <b-form-group label="Module" style="width: 100%" class="mr-1">
            <b-form-select
              v-model="filters.module"
              text-field="name"
              value-field="id"
              :options="filtersData.modules"
              @change="handleModuleChange(filters.module)"
            />
          </b-form-group>
          <b-form-group label="Year" style="width: 100%">
            <b-form-select
              v-model="filters.year"
              text-field="label"
              value-field="value"
              :options="filtersData.years"
              @change="handleYearChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <payroll-payments-general
        v-if="firstFilter.value == 'report'"
        :key="keyGeneral"
        :tab="companyId"
        :year="valueFilterYear"
        :module="valueFilterModule"
        :name-module="valueFilterModuleName"
      />
      <payroll-payments-graphics
        v-else
        :key="keyChart"
        :tab="companyId"
        :module="valueFilterModule"
        @close="closePayrollPaymentsGraphics"
      />
    </b-card>
  </div>
</template>

<script>
import PayrollPaymentsGraphics from "@/views/commons/components/payroll-payments/components/PayrollPaymentsGraphics.vue";
import PayrollPaymentsGeneral from "@/views/commons/components/payroll-payments/components/PayrollPaymentsGeneral.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    PayrollPaymentsGeneral,
    PayrollPaymentsGraphics,
  },
  props: {
    companyId: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      firstFilter: {
        options: [
          {
            text: "Graphic",
            value: "graphic",
          },
          {
            text: "Report",
            value: "report",
          },
        ],
        value: "report",
      },
      // action: "Generate",
      dataGrid: null,
      isBusy: false,
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      sortBy: "",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filtersData: {
        modules: [],
        years: [],
      },
      filters: {
        module: null,
        year: moment().format("YYYY"),
      },
      keyGeneral: 0,
      valueFilterYear: null,
      valueFilterModule: null,
      valueFilterModuleName: null,
      keyChart: 0,
      tablaRecargada: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      generated: "PayrollStore/G_GENERATED",
    }),
  },
  watch: {
    generated(oldval, newval) {
      this.refreshTable();
      this.loadingPayroll = false;
      // this.setGenerated(0);
    },
  },
  async created() {
    await this.getModules();
    this.getYears();
    this.valueFilterYear = parseInt(this.filters.year, 10);
  },
  methods: {
    closePayrollPaymentsGraphics(moduleId) {
      this.filters.module = moduleId;
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filtersData.modules = [{ name: "-- All modules --", id: null }];
        this.filtersData.modules = [
          ...this.filtersData.modules,
          ...response.data,
        ];
      }
    },
    handleYearChange() {
      this.valueFilterYear = this.filters.year;
      this.keyGeneral += 1;
    },
    handleModuleChange() {
      const { id, name } = this.filtersData.modules.find(
        (module) => module.id === this.filters.module
      );
      this.valueFilterModule = id;
      this.valueFilterModuleName = name;
      this.keyGeneral += 1;
    },
    refreshTable() {
      this.keyGeneral += 1;
    },
    getYears() {
      const currentYear = parseInt(moment().format("YYYY"), 10);
      for (let x = currentYear; x >= 2023; x -= 1) {
        this.filtersData.years.push({ label: x, value: x });
      }
    },
  },
};
</script>
<style scoped>
.load {
  animation: spinner 1s linear infinite;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
