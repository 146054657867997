<template>
  <div>
    <header-slot :clients-search="true" :detailBc="false">
      <template #actions>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end mx-1"
          >
            <b-form-select
              v-model="month"
              :options="optionsMonths"
              value-field="id"
              text-field="name"
              size="sm"
              class="mr-2"
              style="max-width: 150px; margin-top: 5px; height: 2.8rem"
            />
            <b-form-select
              v-model="yearSelect"
              :options="optionsYears"
              value-field="id"
              text-field="name"
              size="sm"
              class="mr-2"
              style="max-width: 150px; margin-top: 5px; height: 2.8rem"
            />

            <b-button
              variant="success"
              class="btn-icon mr-1 text-black"
              style="margin-top: 5px"
              :disabled="loadingExcel == true"
              @click="donwloadExcel"
            >
              <b-spinner small v-if="loadingExcel" label="Spinning" />
              <feather-icon v-else icon="FileTextIcon" />
              Excel Payroll Detail
            </b-button>
            <b-button
              variant="primary"
              class="btn-icon mr-1"
              style="margin-top: 5px"
              @click="showModalSendEmail = true"
            >
              <feather-icon icon="FileTextIcon" />
              Views Payrolls
            </b-button>
            <b-form-group class="mr-2">
              <b-dropdown
                id="generate-payroll-payments"
                text="Generate payroll payments"
                variant="warning"
                class="mt-2"
              >
                <template #button-content>
                  <strong class="align-middle">
                    <feather-icon icon="DollarSignIcon" size="14" />
                    Generate Payrolls
                  </strong>
                </template>
                <b-dropdown-item @click="downloadPayroll()">
                  <feather-icon icon="DownloadIcon" size="14" />
                  Excel (to bank)
                </b-dropdown-item>
                <b-dropdown-divider />
                <span id="generatedPayrollPayments">
                  <b-dropdown-item
                    :disabled="loadingPayroll == true"
                    class="text-center"
                    @click="generateAllPayrollPayments()"
                  >
                    <feather-icon
                      :icon="
                        loadingPayroll == true ? 'LoaderIcon' : 'FileTextIcon'
                      "
                      size="14"
                    />
                    Generate All Payrolls
                    <strong>({{ monthTogenerate }})</strong>
                    <b-tooltip
                      placement="bottom"
                      target="generatedPayrollPayments"
                      >To all employees</b-tooltip
                    >
                  </b-dropdown-item>
                </span>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>

    <b-tabs
      lazy
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <div v-for="company in companies" :key="company.id">
        <b-tab
          lazy
          :title="company.display_name_as"
          :title-link-class="[bgTabsNavs, 'px-3']"
          @click="companyIdToPass = company.id"
        >
          <payroll-payments-list :company-id="companyIdToPass" />
          <modal-send-email
            v-if="showModalSendEmail"
            :month-general="parseInt(numberMonthToGenerate, 10)"
            :monthName="monthTogenerate"
            :company_id="companyIdToPass"
            :year-select="yearSelect"
            @closeModal="closeModalSendEmail"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
// eslint-disable-next-line import/extensions
import PayrollPaymentsService from "@/views/commons/components/payroll-payments/payroll-payments.service.js";
import PayrollPaymentsList from "@/views/commons/components/payroll-payments/PayrollPaymentsList.vue";
import ModalSendEmail from "@/views/commons/components/payroll-payments/modals/ModalPayrollPayments.vue";

export default {
  name: "PayrollPaymentsMain",
  components: {
    PayrollPaymentsList,
    ModalSendEmail,
  },
  data() {
    return {
      month: null,
      optionsMonths: [
        { id: "01", name: "January" },
        { id: "02", name: "February" },
        { id: "03", name: "March" },
        { id: "04", name: "April" },
        { id: "05", name: "May" },
        { id: "06", name: "June" },
        { id: "07", name: "July" },
        { id: "08", name: "August" },
        { id: "09", name: "September" },
        { id: "10", name: "October" },
        { id: "11", name: "November" },
        { id: "12", name: "December" },
      ],
      yearSelect: null,
      optionsYears: [],
      showModalSendEmail: false,
      showModalApprovedPayrollPayments: false,
      generatedPayrollPaymentsLastMonth: false,
      loadingPayroll: false,
      companyIdToPass: 1,
      companies: [],
      loadingExcel: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      generated: "PayrollStore/G_GENERATED",
    }),

    monthTogenerate() {
      return moment(this.month, "MM").format("MMMM");
    },
    numberMonthToGenerate() {
      return moment(this.month, "MM").format("MM");
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
  },
  watch: {
    generated(oldval, newval) {
      this.loadingPayroll = false;
      this.setGenerated(0);
    },
  },
  async mounted() {
    this.month = moment().subtract(1, "months").format("MM");
    await this.getActiveCompanies();
    await this.existPayrollPayments();
    this.generateYears();
  },
  methods: {
    async donwloadExcel() {
      try {
        this.loadingExcel = true;
        const namefile = `PayrollDetail_${moment().format(
          "YYYY_MM_DD_HH_MM_SS"
        )}.xlsx`;
        const params = {
          page: null,
          perPage: null,
          sortBy: null,
          name_text: null,
          sortOrder: null,
          filterMonth: parseInt(this.numberMonthToGenerate, 10),
          module_id: null,
          user_id: null,
          filterYear: this.yearSelect,
          tab: parseInt(this.companyIdToPass, 10),
          is_excel: 1,
          name_file: namefile,
        };
        const data = await PayrollPaymentsService.generateDetailPayrollExcel(
          params
        );

        await this.forceFileDownload(data.data, namefile);

        this.loadingExcel = false;
      } catch (error) {
        this.loadingExcel = false;
        this.showErrorSwal(error);
        console.log(error, "error to download excel");
      }
    },
    ...mapActions({
      setGenerated: "PayrollStore/A_SET_GENERATED_PAYROLL",
    }),
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];
      this.yearSelect = this.month == 12 ? currentYear - 1 : currentYear;
      for (let i = beginningYear; i <= this.yearSelect; i++) {
        this.optionsYears.push({ id: i, name: i });
      }
      this.showMonthsActives();
    },

    showMonthsActives() {
      const today = new Date();
      const currentYear = today.getFullYear();
      if (this.yearSelect == currentYear) {
        this.optionsMonths = this.optionsMonths.filter(
          (month) => month.id <= today.getMonth() + 1
        );
        this.month = this.optionsMonths[this.optionsMonths.length - 1].id;
      } else {
        this.optionsMonths = [
          { id: "01", name: "January" },
          { id: "02", name: "February" },
          { id: "03", name: "March" },
          { id: "04", name: "April" },
          { id: "05", name: "May" },
          { id: "06", name: "June" },
          { id: "07", name: "July" },
          { id: "08", name: "August" },
          { id: "09", name: "September" },
          { id: "10", name: "October" },
          { id: "11", name: "November" },
          { id: "12", name: "December" },
        ];
        this.month = this.optionsMonths[11].id;
      }
    },

    async getActiveCompanies() {
      try {
        this.addPreloader();
        const data = await PayrollPaymentsService.getActiveCompanies();
        if (data.status === 200) {
          this.companies = data.data;
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        console.log("error getActiveCompanies", error);
      }
    },
    async existPayrollPayments() {
      try {
        const params = {
          month: parseInt(this.numberMonthToGenerate, 10),
          year: moment().format("YYYY"),
        };
        const { data } =
          await PayrollPaymentsService.existPayrollPaymentEmployees(params);

        this.generatedPayrollPaymentsLastMonth = data;
      } catch (error) {
        console.log("error existPayrollPayments", error);
      }
    },
    async generateAllPayrollPayments() {
      try {
        const result = await this.showConfirmSwal(
          `Are you sure you want to ${
            this.generatedPayrollPaymentsLastMonth ? "Regenerate" : "Generate"
          } payroll payments for all employees?`
        );
        if (!result.isConfirmed) return;
        this.loadingPayroll = true;

        this.addPreloader();
        const params = {
          month: parseInt(this.numberMonthToGenerate, 10),
          user_id: this.currentUser.user_id,
          company_id: this.companyIdToPass,
        };
        const data =
          await PayrollPaymentsService.generatePayrollPaymentAllEmployees(
            params
          );
        if (data.status === 200) {
          this.showSuccessSwal(
            "Wait some seconds until the payroll is generated."
          );
          await this.existPayrollPayments();
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal("Error generating payroll payments");
        console.log("error generateAllPayrollPayments", error);
        this.removePreloader();
      }
    },
    async downloadPayroll() {
      // Setting actual year and last month to get payroll payment
      const params = {
        month: parseInt(this.numberMonthToGenerate, 10),
        year: moment().format("YYYY"),
        company_id: this.companyIdToPass,
      };
      this.addPreloader();
      try {
        // getting response from controller
        const response = await PayrollPaymentsService.getPayrollPaymentGeneral(
          params
        );
        // Assign response for download in frontend
        if (response.status === 205) {
          this.showWarningSwal(
            "Employees Accounts were not found",
            "Please fill data"
          );
          this.removePreloader();
          return;
        }
        await this.getPayrollReport(
          response,
          `${params.month}_${params.year}_payroll.xlsx`
        );
        this.removePreloader();

        this.showSuccessSwal("Payroll Payment Generated");
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal("Payroll Payment not generated");
      }
    },
    /// This  method is for download file in frontend
    async getPayrollReport(response, fileName) {
      const blob = new Blob([response.data], { type: "text/plain" });
      if (window?.navigator && window?.navigator.msSaveOrOpenBlob) {
        // For not Chromium browsers (IE, Edge)
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // Para otros navegadores
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
    },
    closeModalSendEmail() {
      this.showModalSendEmail = false;
    },
  },
  watch: {
    yearSelect() {
      this.showMonthsActives();
    },
  },
};
</script>
