<template>
  <b-sidebar
    id="sidebar-detail"
    v-model="isDetailsActive"
    title="Payroll Payment Report Detail"
    header-class="text-primary pt-1"
    sidebar-class="sidebar-xmd"
    bg-variant="white"
    shadow
    backdrop
    right
    @hidden="close"
  >

    <div>
      <b-container
        fluid="md"
      >

        <b-row>
          <b-col>
            <!-- <custom-input-group
              label="YEAR/MONTH"
              :value="year + ' / '+ name_month"
            /> -->
            <b-card
              :style="isDarkSkin ? 'background: #1c212b; color: #ffffff' : 'background: #edf3fd; color: #000000'"
              title="YEAR/MONTH"
            >
              <b-card-text>
                {{ year + ' / '+ name_month }}
              </b-card-text>

            </b-card>
          </b-col>
          <b-col>
            <!-- <custom-input-group
              label="PAYMENTS"
              :value="month==currentMonth?'Pending':'S/ '+valueContent[0]"
            /> -->
            <b-card
              :style="isDarkSkin ? 'background: #1c212b; color: #ffffff' : 'background: #edf3fd; color: #000000'"
              title="PAYMENTS"
            >
              <b-card-text>
                {{ month==currentMonth?'Pending':'S/ '+valueContent[0] }}
              </b-card-text>

            </b-card>
          </b-col>

          <b-col>
            <!-- <custom-input-group
              label="CONTRIBUTIONS"
              :value="month==currentMonth?'Pending':'S/ '+valueContent[1]"
            /> -->

            <b-card
              :style="isDarkSkin ? 'background: #1c212b; color: #ffffff' : 'background: #edf3fd; color: #000000'"
              title="CONTRIBUTIONS"
            >
              <b-card-text>
                {{ month==currentMonth?'Pending':'S/ '+valueContent[1] }}
              </b-card-text>

            </b-card>
          </b-col>

          <b-col>
            <!-- <custom-input-group
              label="DISCOUNTS"
              :value="month==currentMonth?'Pending':'S/ '+valueContent[2]"
            /> -->

            <b-card
              :style="isDarkSkin ? 'background: #1c212b; color: #ffffff' : 'background: #edf3fd; color: #000000'"
              title="DISCOUNTS"
            >
              <b-card-text>
                {{ month==currentMonth?'Pending':'S/ '+valueContent[2] }}
              </b-card-text>

            </b-card>
          </b-col>

          <b-col cols="3">
            <!-- <custom-input-group
              label="MODULE"
              :value="name_module"
            /> -->

            <b-card
              :style="isDarkSkin ? 'background: #1c212b; color: #ffffff' : 'background: #edf3fd; color: #000000'"
              title="MODULE"
            >
              <b-card-text>
                {{ name_module }}
              </b-card-text>

            </b-card>
          </b-col>
        </b-row>

      </b-container></div>

    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :change-columns-by-client="true"
        @reload="$refs['refPayrollPayments'].refresh()"
      >
        <b-table
          slot="table"
          ref="refPayrollPayments"
          :items="getEmployees"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          sticky-header="45vh"
          show-empty
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="table-scroll-per"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(employee_name)="data">
            <b-row>
              <b-col class="py-1 d-flex align-items-center">
                <span
                  class="text-primary"
                >
                  {{ (data.item.first_name +' '+ data.item.employee_name?data.item.employee_name:'').toUpperCase() }}
                </span>
              </b-col>
              <b-col>
                <span>
                  Payment date: <b-badge variant="info">
                    {{ data.item.payment_date !== null ? data.item.payment_date : 'pending' }}</b-badge>
                </span>
              </b-col>
            </b-row>
          </template>
          <template #cell(gross_salary)="data">

            <div class="d-flex justify-content-center">
              <span
                v-b-tooltip.hover.top="'REMUNERACIÓN O JORNAL BÁSICO'"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  v-b-tooltip.hover.top="'Approve justification'"
                  :value="data.item.salary_base"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />

              </span>
            </div>
            <div class="d-flex justify-content-center">
              <span
                v-b-tooltip.hover.top="'MOV SUPEDIT A ASSIST CUBRE TRASLADO'"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.bonification_base"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
            </div>
            <hr>
            <div class="d-flex justify-content-center">
              <span
                v-b-tooltip.hover.top="'SALARIO REAL'"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.gross_salary"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
            </div>
          </template>

          <template #cell(incomes)="data">

            <div
              v-for="(item, index) in JSON.parse(data.item.incomes)"
              :key="index"
              class="d-flex justify-content-center"
            >
              <span

                v-b-tooltip.hover.top="item.concept"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  v-b-tooltip.hover.top="'Approve justification'"
                  :value="item.amount"
                  v-bind="{

                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />

              </span>
            </div>

          </template>
          <template #cell(discounts)="data">

            <div
              v-for="(item, index) in JSON.parse(data.item.discounts)"
              :key="index"
              class="d-flex justify-content-center"
            >
              <span

                v-b-tooltip.hover.top="item.concept"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  v-b-tooltip.hover.top="'Approve justification'"
                  :value="item.amount"
                  v-bind="{

                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />

              </span>
            </div>

          </template>
          <template #cell(hour_cost)="data">
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.hour_cost"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
            </div>
          </template>
          <template #cell(not_worked_hours)="data">
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >
                {{ data.item.not_worked_hours }} hrs
              </span>
            </div>
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >

                <money
                  :value="data.item.not_worked_hours_amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />

              </span>
            </div>
          </template>

          <template #cell(salary_with_discounts)="data">
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.salary_with_discounts"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
            </div>
          </template>
          <template #cell(pension_name)="data">
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >
                {{ data.item.pension_name }}
              </span>
            </div>
            <div class="d-flex justify-content-center">
              <span
                v-if="data.item.amount"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
              <span
                v-else
                style="padding: 0 10px; border-radius: 5px"
              >
                -
              </span>
            </div>
            <div class="d-flex justify-content-center">
              <span
                v-if="data.item.prima"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.prima"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
              <span
                v-else
                style="padding: 0 10px; border-radius: 5px"
              >
                -
              </span>
            </div>
            <div class="d-flex justify-content-center">
              <span
                v-if="data.item.comission"
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.comission"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
              <span
                v-else
                style="padding: 0 10px; border-radius: 5px"
              >
                -
              </span>
            </div>
            <hr>

            <div class="d-flex justify-content-center">

              <span
                v-if="data.item.insurance_discount"
                style="padding: 0 10px; border-radius: 5px"
              >

                <money
                  :value="data.item.insurance_discount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
              <span
                v-else
                style="padding: 0 10px; border-radius: 5px"
              >
                -
              </span>
            </div>
          </template>

          <template #cell(insurance_discount)="data" />
          <template #cell(total_to_pay)="data">
            <div class="d-flex justify-content-center">
              <span
                style="padding: 0 10px; border-radius: 5px"
              >
                <money
                  :value="data.item.total_to_pay"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none;width:auto;text-align:center"
                  :style="isDarkSkin?'color:white':'color:black'"
                  disabled
                />
              </span>
            </div>
          </template>
          <template #custom-foot>

            <b-tr>
              <b-th />
              <b-th class="d-flex justify-content-center">
                <h3><b-badge
                      v-if="grossSalary !== 0.00"
                      variant="primary"
                    >
                      {{ formatAmount(grossSalary) }}
                    </b-badge>
                  <b-badge
                    v-else
                    variant="info"
                  >
                    NO DATA
                  </b-badge>
                </h3>
              </b-th>
              <b-th />
              <b-th />
              <b-th />
              <b-th />
              <b-th />

              <b-th class="d-flex justify-content-center">
                <h3><b-badge
                      v-if="grossSalary !== 0.00"
                      variant="primary"
                    >
                      {{ formatAmount(totalPay) }}
                    </b-badge>
                  <b-badge
                    v-else
                    variant="info"
                  >
                    NO DATA
                  </b-badge>
                </h3>
              </b-th>
            </b-tr>

          </template>
        </b-table>
      </filter-slot>
    </div>
  </b-sidebar>

</template>

<script>
import PayrollPaymentsService from '@/views/commons/components/payroll-payments/payroll-payments.service';
import moment from 'moment';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  props: {
    tab:
    {
      type: Number,
      default: () => {},
    },
    content:
    {
      type: Array,
      default: () => {},
    },
    month: {
      type: Number,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
    module: {
      type: Number,
      default: null,
    },
    nameModule: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: '',
      sortDesc: true,
      fields: [
        {
          key: 'employee_name',
          label: 'Employee',
          visible: true,
        },
        {
          key: 'gross_salary',
          label: 'Real Salary',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'incomes',
          label: 'Incomes',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'discounts',
          label: 'Discounts',
          thClass: 'text-center',
          visible: true,
        },

        {
          key: 'hour_cost',
          label: 'Cost per hour',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'not_worked_hours',
          label: 'Hours not worked',
          thClass: 'text-center',
          visible: true,
        },

        {
          key: 'pension_name',
          label: 'Pension name',
          thClass: 'text-center',
          visible: true,
        },
        // {
        //   key: 'pension_regimen',
        //   label: 'Cost per hour',
        //   thClass: 'text-center',
        //   visible: true,
        // },

        {
          key: 'total_to_pay',
          label: 'Total to pay',
          thClass: 'text-center',
          visible: true,
        },
      ],
      filtersData: {
        modules: [],
      },
      filters: [
        {
          type: 'select',
          label: 'Module',
          model: null,
          reduce: 'id',
          selectText: 'name',
          options: [],
        },
      ],
      listApprovedOvertime: [],
      showUpload: false,
      showTracking: false,
      showAll: false,
      titleModal: '',
      actionJustification: null,
      showUploadMedicalRest: false,
      userIdSelected: null,
      dataGrid: null,
      isDetailsActive: false,
      name_month: null,
      id_module: null,
      name_module: null,
      valueContent: [],
      currentMonth: null,
      monthValues: [
        '',
        'value_jan',
        'value_feb',
        'value_mar',
        'value_apr',
        'value_may',
        'value_jun',
        'value_jul',
        'value_aug',
        'value_sep',
        'value_oct',
        'value_nov',
        'value_dec',
        'value_total',
      ],
    };
  },
  computed: {
    // funcion para sumar todos los total_pay
    totalPay() {
      let total = 0;
      if (this.dataGrid) {
        this.dataGrid.forEach(element => {
          total += parseFloat(element.total_to_pay);
        });
      }
      return total;
    },
    // gross_salary
    grossSalary() {
      let total = 0;
      if (this.dataGrid) {
        this.dataGrid.forEach(element => {
          total += parseFloat(element.gross_salary);
        });
      }
      return total;
    },
  },
  async mounted() {
    this.isDetailsActive = true;
    this.id_module = this.module;
    this.name_month = moment().month(this.month - 1).format('MMMM');
    await this.getModules();
  },
  created() {
    this.currentMonth = parseInt(moment().format('M'), 10);
    this.name_module = this.module != null ? this.nameModule : 'All Modules';
  },
  methods: {

    formatAmount(value) {
      // Redondea el valor a 2 decimales y conviértelo a cadena con 2 decimales fijos
      // return parseFloat(value).toFixed(2);
      const prefix = 'S/ ';
      const formattedValue = parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `${prefix}${formattedValue}`;
    },

    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filtersData.modules = [{ name: '-- All modules --', id: null }];
        this.filtersData.modules = [
          ...this.filtersData.modules,
          ...response.data,
        ];
        this.filters[0].options = this.filtersData.modules;
      }
    },
    close() {
      this.$emit('close');
    },
    async getEmployees(ctx) {
      try {
        // si this.filters[0].model es nulo y this.module no lo es
        // cambiar el valor de this.filters[0]
        if (this.filters[0].model === null && this.id_module !== null) {
          this.filters[0].model = this.id_module;
        }
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? 'ASC' : 'DESC',
          orderBy: 10,
          order: 'DESC',
          module_id: this.filters[0].model,
          user_id: null,
          filterMonth: this.month,
          filterYear: this.year,
          tab: this.tab,
        };
        this.addPreloader();
        const data = await PayrollPaymentsService.getPayrollPaymentsEmployees(params);
        const response = await PayrollPaymentsService.getPayrollPaymentsReport({
          year: this.year != null ? this.year : moment().format('YYYY'),
          tab: this.tab,
          module_id: (this.filters[0].model === null ? this.id_module : this.filters[0].model),
        });
        if (data.status === 200) {
          this.removePreloader();
          // borrar valueContent
          this.valueContent = [];
          if (response.data.length > 0) {
            response.data.forEach(item => {
              this.valueContent.push(item[this.monthValues[this.month]]);
            });
          } else {
            this.valueContent.push(0);
            this.valueContent.push(0);
            this.valueContent.push(0);
          }
          this.dataGrid = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;

          if (this.dataGrid && this.dataGrid.length > 0) {
            this.hour_holidays = this.dataGrid[0].hours_holyday;
          }
          const { name } = this.filtersData.modules.find(
            module => module.id === (this.filters[0].model === null ? this.id_module : this.filters[0].model),
          );
          this.name_module = name;
          this.id_module = null;
          return this.dataGrid || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
      return [];
    },
  },
};
</script>
<style scoped>
.table-scroll-per tfoot tr,
.table-scroll-per tfoot th {
  position: sticky !important;
  bottom: 0 !important;
  z-index: 2;
}

.sidebar-xl {
  width: 90rem;
}
</style>
