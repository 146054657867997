var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"scrollable":"","title-class":"h3 text-white font-weight-bolder text-center","size":"lg","title":("Update fifth category calculation " + (_vm.dataEmployee.know_as))},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"d-flex justify-content-center",attrs:{"variant":"success"},on:{"click":function($event){return _vm.validatePaymentDate()}}},[_vm._v(" CONFIRM ")])]},proxy:true}]),model:{value:(_vm.showPreviewModal),callback:function ($$v) {_vm.showPreviewModal=$$v},expression:"showPreviewModal"}},[_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',[_c('p',{staticClass:"p-1 text-warning"},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon"}}),_vm._v(" Make sure the months don't repeat ")],1)]),_c('b-col',{staticClass:"text-right align-self-center"},[_c('b-button',{attrs:{"disabled":_vm.paymentDetails.length >= 7,"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.addPaymentDetails()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}}),_vm._v(" Add Contribution ")],1)],1)],1),(_vm.paymentDetails.length === 0)?_c('b-col',{staticClass:"text-center py-2"},[_c('h4',{staticClass:"text-warning font-weight-bolder"},[_vm._v(" Without fifth category registrations, add one registration. ")])]):_vm._e(),_c('validation-observer',{ref:"form"},_vm._l((_vm.paymentDetails),function(item,index){return _c('b-row',{key:index},[_c('b-col',[_c('validation-provider',{attrs:{"name":("amount_" + index),"rules":"required|max_value:99999999|min_value:0.1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount","label-for":"amount"}},[_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger rounded' : '',attrs:{"id":("amount_" + index),"v-mask":'#########',"value":item.amount,"state":errors[0] ? false : null},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: 'S/',
                  precision: 2,
                  maxlength: 11,
                  masked: false,
                },false))],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":("payment_date_" + index),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment date","label-for":"Payment date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],class:errors[0] ? 'border-danger rounded' : '',attrs:{"state":errors[0] ? false : null,"placeholder":"MM/DD/YYYY","format":'MM/dd/yyyy'},model:{value:(item.date_contribution),callback:function ($$v) {_vm.$set(item, "date_contribution", $$v)},expression:"item.date_contribution"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[[(item.date_contribution)?_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(new Date(item.date_contribution).toLocaleString('default', { month: 'long' }).toUpperCase())+" ")]):_c('h5',{staticClass:"text-warning"},[_vm._v(" Without payment date ")])]],2),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"Trash2Icon","size":"25"},on:{"click":function($event){return _vm.deletePaymentsIdS(item.id,index)}}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }