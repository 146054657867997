<template>
  <b-card class="p-2">
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="2"
        :class="isDarkSkin ? 'bg-dark' : 'bg-white'"
        class="pt-1 rounded"
      >
        <b-form-group label="Module">
          <b-form-select
            v-model="filters.module"
            text-field="name"
            value-field="id"
            :options="filtersData.modules"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="2"
        class="pt-1"
      >
        <b-form-group label="Month">
          <b-form-select
            v-model="filters.month"
            text-field="label"
            value-field="value"
            :options="filtersData.months"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="2"
        class="pt-1"
      >
        <b-form-group label="Year">
          <b-form-select
            v-model="filters.year"
            text-field="label"
            value-field="value"
            :options="filtersData.years"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="auto"
        sm="12"
        md="12"
        lg="auto"
        class="my-2 rounded py-1 px-2 mt-1 shadow w-auto d-block d-lg-flex justify-content-center align-items-center"
        style="margin-left: 20px"
        :class="!isDarkSkin ? 'bg-white' : 'bg-dark'"
      >
        <span
          style="font-size: 15px"
          class="mr-2 d-flex justify-content-center align-items-center text-center rounded"
        >
          Showing
          <span style="border-radius: 3px; padding: 0 5px; margin: 5px">{{
            startPage
          }}</span>
          to
          <span style="border-radius: 3px; padding: 0 5px; margin: 5px">{{
            toPage
          }}</span>
          of
          <span style="border-radius: 3px; padding: 0 5px; margin: 5px">{{
            totalRows
          }}</span>
          employees
        </span>
        <b-col
          cols="12"
          md="auto"
          class="d-flex justify-content-center justify-content-lg-start text-lg-center"
        >
          <b-button
            variant="info"
            class="btn-icon rounded-circle mr-1"
            :disabled="disabledBtnBefore"
            @click="changeEmployees(-1)"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="info"
            class="btn-icon rounded-circle"
            :disabled="disabledBtnNext"
            @click="changeEmployees(1)"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-col>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div
            class="px-2 py-1 rounded bg-primary mt-1 text-white mr-1 text-center"
          >
            <strong>Total Pays</strong>
            <strong class="ml-2">S/. {{ totalPay }}</strong>
          </div>
          <div
            class="px-2 py-1 rounded bg-warning mt-1 text-white mr-1 text-center"
          >
            <strong>Total Discounts</strong>
            <strong class="ml-2">S/. {{ totalDiscounts }}</strong>
          </div>
          <div
            class="px-2 py-1 rounded bg-success mt-1 text-white mr-1 text-center"
          >
            <strong>Total contributions</strong>
            <strong class="ml-2">S/. {{ totalContributions }}</strong>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-card
        v-if="!thereAreData"
        no-body
        class="w-100"
      >
        <b-container style="min-height: 400px">
          <div
            class="flex-wrap d-flex justify-content-center align-items-center"
          >
            <img
              src="https://tagcor.com/jobs_assets/images/icons/error.png"
              class="no-pointer-events mt-4 p-4"
              width="400px"
              alt="Error Icon"
            >
            <strong
              class="ml-3 d-inline-flex text-center text-center"
              style="font-size: 3rem"
            >NOT DATA FOUND</strong>
          </div>
        </b-container>
      </b-card>
      <b-col
        v-else
        class="p-3"
      >
        <vue-apex-charts
          :key="keyGraphics"
          type="bar"
          width="100%"
          height="500"
          :options="chartOptions"
          :series="series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';
import moment from 'moment';
import payrollPaymentsService from '@/views/commons/components/payroll-payments/payroll-payments.service';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    tab: {
      require: true,
      type: Number,
      default: null,
    },
    module: {
      require: true,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      disabledBtnBefore: true,
      disabledBtnNext: true,
      keyGraphics: 0,
      startPageGraphic: 0,
      toPageGraphic: 0,
      totalRowsGraphic: 0,
      totalPay: 0.0,
      totalDiscounts: 0.0,
      totalContributions: 0.0,
      filters: {
        month: moment().subtract(1, 'months').format('MM'),
        year: moment().format('YYYY'),
        module: null,
      },
      filtersData: {
        modules: [],
        years: [],
        months: [],
      },
      series: [
        {
          name: 'Salary',
          data: [],
        },
        {
          name: 'Discount',
          // data: [
          // ],
          data: [],
        },
        {
          name: 'Contributions',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'flat',
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2],
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent'],
        },
        colors: ['#2591E7', '#F39D40', '#6ED45F'],
        xaxis: {
          title: {
            text: 'EMPLOYEES',
          },
          categories: [],
          labels: {
            show: true,
            style: {
              colors: 'black',
            },
          },
        },
        legend: {
          position: 'top',
          offsetX: -20,
          labels: { colors: true },
        },
        yaxis: {
          title: {
            text: 'S/. THOUSANDS',
          },
          labels: {
            show: true,
            style: {
              colors: 'black',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter(val) {
              return `S/. ${val.toFixed(2)}`;
            },
          },
        },
      },
      page: 1,
      perPage: 10,
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      dataGraphics: [],
    };
  },
  computed: {
    ...mapGetters({
      skin: 'appConfig/skin',
    }),
    thereAreData() {
      return this.dataGraphics.length > 0;
    },
  },
  watch: {
    async skin(oldVal) {
      if (oldVal === 'dark') {
        this.chartOptions.xaxis.labels.style.colors = 'white';
        this.chartOptions.yaxis.labels.style.colors = 'white';
      } else {
        this.chartOptions.xaxis.labels.style.colors = 'black';
        this.chartOptions.yaxis.labels.style.colors = 'black';
      }
      this.keyGraphics += 1;
      const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
      const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
          const tooltipTitle = chartContext.el.querySelector(
            '.apexcharts-tooltip-title',
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = '1px solid #000000';
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = '1px solid #000000';
          }
        },
      };
      // eslint-disable-next-line no-undef
      // this.chart = new ApexCharts(this.$refs.chart, options);
      // this.chart.render();
    },

    // eslint-disable-next-line func-names
    'filters.module': async function () {
      this.page = 1;
      await this.getData();
      await this.getTotalAmounts();
    },
    // eslint-disable-next-line func-names
    'filters.year': async function () {
      this.page = 1;
      this.getMonths();
      await this.getData();
      await this.getTotalAmounts();
    },
    // eslint-disable-next-line func-names
    'filters.month': async function () {
      this.page = 1;
      await this.getData();
      await this.getTotalAmounts();
    },
  },
  async mounted() {
    await this.getModules();
    this.filters.module = this.module;

    this.getYears();
    this.getMonths();
    if (!this.thereAreData) {
      if (this.isDarkSkin) {
        this.chartOptions.xaxis.labels.style.colors = 'white';
        this.chartOptions.yaxis.labels.style.colors = 'white';
      } else {
        this.chartOptions.xaxis.labels.style.colors = 'black';
        this.chartOptions.yaxis.labels.style.colors = 'black';
      }
      const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
      const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
          const tooltipTitle = chartContext.el.querySelector(
            '.apexcharts-tooltip-title',
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = '1px solid #000000';
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = '1px solid #000000';
          }
        },
      };
    }
    await this.getData();
    await this.getTotalAmounts();
  },
  destroyed() {
    this.$emit('close', this.filters.module);
  },
  methods: {
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filtersData.modules = [{ name: '-- All modules --', id: null }];
        this.filtersData.modules = [
          ...this.filtersData.modules,
          ...response.data,
        ];
      }
    },
    initChart() {
      const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
      const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
          const tooltipTitle = chartContext.el.querySelector(
            '.apexcharts-tooltip-title',
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = '1px solid #000000';
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = '1px solid #000000';
          }
        },
      };
      // eslint-disable-next-line no-undef
      this.chart = new ApexCharts(this.$refs.chart, options);
      this.chart.render();
    },
    async changeEmployees(page) {
      this.page += page;
      await this.getData();
      this.disabledBtnBefore = this.startPage === 1;
      this.disabledBtnNext = this.toPage === this.totalRows;
    },
    getYears() {
      const currentYear = new Date().getFullYear();
      this.filtersData.years = [];
      for (let i = 2023; i <= currentYear; i += 1) {
        this.filtersData.years.push({ value: i, label: i });
      }
    },
    getMonths() {
      const currentYear = parseInt(moment().format('YYYY'), 10);
      let countMonths = 12;
      if (parseInt(this.filters.year, 10) === currentYear) {
        // show until previous month
        countMonths = parseInt(moment().subtract(1, 'months').format('MM'), 10);
        this.filters.month = this.filters.month < countMonths ? this.filters.month : countMonths;
      }

      this.filtersData.months = [];
      for (let index = 1; index <= countMonths; index += 1) {
        this.filtersData.months.push({
          value: index,
          label: moment(index, 'MM').format('MMMM'),
        });
      }
    },
    async getData() {
      try {
        const params = {
          page: this.page,
          perPage: this.perPage,
          sortBy: 1,
          name_text: null,
          sortOrder: 'DESC',
          filterMonth: this.filters.month,
          module_id: this.filters.module,
          user_id: null,
          filterYear: parseInt(this.filters.year, 10),
          tab: parseInt(this.tab, 10),
        };
        const { data } = await payrollPaymentsService.getPayrollPaymentsEmployees(params);
        this.dataGraphics = data.data;
        if (this.dataGraphics) {
          this.getValuesGraphics(this.dataGraphics);
        }

        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.totalRows = data.total || 0;
        this.disabledBtnBefore = this.startPage <= 1;
        this.disabledBtnNext = this.toPage === this.totalRows;
      } catch (error) {
        this.showErrorSwal();
        console.log('error get Data', error);
      }
    },
    getValuesGraphics(data) {
      const totalPayByEmployees = [];
      const totalDiscountByEmployees = [];
      const totalContributionByEmployees = [];
      const employees = [];

      // eslint-disable-next-line array-callback-return
      if (data.length > 0) {
        data.map(item => {
          totalPayByEmployees.push(parseFloat(item.total_to_pay || 0, 10));
          totalDiscountByEmployees.push(
            parseFloat(item.total_discount || 0, 10),
          );
          totalContributionByEmployees.push(
            parseFloat(item.total_contributions || 0, 10),
          );

          employees.push(item.first_name);
        });
      }
      if (data.length < 10) {
        const missingQuantity = 10 - data.length;

        // generated values null to maintain order of the columns
        for (let index = 0; index < missingQuantity; index += 1) {
          totalPayByEmployees.push(null);
          totalDiscountByEmployees.push(null);
          totalContributionByEmployees.push(null);
          employees.push('');
        }
      }

      this.series[0].data = data.length > 0 ? totalPayByEmployees : [];
      this.series[1].data = data.length > 0 ? totalDiscountByEmployees : [];
      this.series[2].data = data.length > 0 ? totalContributionByEmployees : [];
      this.chartOptions.xaxis.categories = employees;

      this.keyGraphics += 1;
    },
    async getTotalAmounts() {
      try {
        const params = {
          year: this.filters.year,
          tab: parseInt(this.tab, 10),
          module_id: this.filters.module,
        };
        const { data } = await payrollPaymentsService.getPayrollPaymentsReport(
          params,
        );
        const valuesMonth = [
          'value_jan',
          'value_feb',
          'value_mar',
          'value_apr',
          'value_may',
          'value_jun',
          'value_jul',
          'value_aug',
          'value_sep',
          'value_oct',
          'value_nov',
          'value_dec',
        ];
        this.totalPay = data.length
          ? data[0][valuesMonth[this.filters.month - 1]]
          : 0;
        this.totalContributions = data.length
          ? data[1][valuesMonth[this.filters.month - 1]]
          : 0;
        this.totalDiscounts = data.length
          ? data[2][valuesMonth[this.filters.month - 1]]
          : 0;
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>
