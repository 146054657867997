<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <b-table-simple responsive>
      <b-thead class="custom-table">
        <b-tr>
          <b-th
            class="sticky-column-first"
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
            ><span :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >TYPE</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >JAN</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >FEB</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >MAR</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >APR</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >MAY</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >JUN</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >JUL</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >AUG</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >SEP</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >OCT</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >NOV</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
          >
            <span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >DEC</span
            >
          </b-th>
          <b-th
            :style="isDarkTheme ? 'background: #3D95E7' : 'background: #D8EEFC'"
            class="sticky-column-last"
            ><span
              class="d-flex justify-content-center"
              :style="isDarkTheme ? 'color:white' : 'color:#3D95E7'"
              >TOTAL</span
            >
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody v-if="reports.length > 0" style="font-size: 12px">
        <template v-for="(item, index) in reports">
          <b-tr :key="index" meta="FFFFC3">
            <b-th
              class="font-size-09 font-weight-bolder sticky-column-first"
              :style="'white-space: nowrap;font-weight: bolder'"
              :class="isDarkTheme ? 'background-dark' : 'background-light'"
            >
              {{ item.col1 }}
            </b-th>
            <b-td
              v-for="indexCont in 12"
              :key="indexCont"
              :class="isDarkTheme ? 'background-dark' : 'background-light'"
            >
              <span
                v-if="year == currentYear ? indexCont != currentMonth : true"
                class="d-flex justify-content-center"
              >
                <strong
                  v-if="
                    indexCont === currentMonth - 1 &&
                    item[monthValues[indexCont]] === 0
                  "
                  class="d-flex justify-content-center"
                  :style="{
                    'white-space': 'nowrap',
                    padding: '2px',
                    'font-weight': 'bolder',
                    'border-radius': '5px',
                    'background-color': '#ff9f43',
                    color: 'white',
                  }"
                >
                  Generate
                </strong>
                <money
                  v-else-if="
                    year == currentYear ? indexCont < currentMonth : true
                  "
                  :value="item[monthValues[indexCont]]"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none; width: auto; text-align: center"
                  :style="isDarkTheme ? 'color:white' : 'color:black'"
                  disabled
                />
                <strong v-else> - </strong>
              </span>
              <span
                v-else
                class="d-flex justify-content-center"
                :style="{
                  'white-space': 'nowrap',
                  padding: '2px',
                  'font-weight': 'bolder',
                  'border-radius': indexCont === currentMonth ? '5px' : 'none',
                  'background-color':
                    indexCont === currentMonth ? '#5e81ac' : 'transparent',
                  color: indexCont === currentMonth ? 'white' : '',
                }"
              >
                Pending
              </span>
            </b-td>
            <b-td
              :key="indexCont"
              :class="isDarkTheme ? 'background-dark' : 'background-light'"
              class="sticky-column-last"
            >
              <span
                class="d-flex justify-content-center"
                :style="'white-space: nowrap;font-weight: bolder;background:gray;border-radius:5px;padding:5px;color:white'"
              >
                <money
                  :class="isDarkSkin ? 'text-white' : ''"
                  :value="item.total_sum ? item.total_sum : 0"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none; color: white"
                  disabled
                />
              </span>
            </b-td>
          </b-tr>
        </template>
        <b-tr>
          <b-td
            class="font-size-09 font-weight-bolder sticky-column-first"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            DETAILS
          </b-td>
          <b-td
            v-for="index in 12"
            :key="index"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            <div class="d-flex justify-content-center">
              <b-badge
                v-if="
                  (year == currentYear && index === currentMonth) ||
                  (reports[0][monthValues[index]] != '-' &&
                    reports[0][monthValues[index]] != 0)
                "
                :style="
                  isDarkTheme
                    ? 'background:#2ACB57;color:white'
                    : 'background:#D7FFE2;color:#2ACB57'
                "
                style="cursor: pointer"
                @click="viewDetails(index)"
              >
                DETAILS
              </b-badge>
              <b-badge
                v-else
                :style="
                  isDarkTheme
                    ? 'background:#FC424A;color:white'
                    : 'background:#FFCDD0;color:#FC424A'
                "
              >
                NO DETAILS
              </b-badge>
            </div>
          </b-td>
          <b-td
            class="font-size-09 font-weight-bolder sticky-column-last"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          />
        </b-tr>
      </b-tbody>
      <b-tbody v-else style="font-size: 12px">
        <b-tr
          class="background-1 font-size-09 font-weight-bolder"
          :style="'white-space: nowrap;font-weight: bolder'"
          :class="isDarkTheme ? 'background-dark' : 'background-light'"
        >
          <!-- poner payments a la primera columna -->
          <b-td
            class="background-1 font-size-09 font-weight-bolder sticky-column-first"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
            colspan="1"
          >
            PAYMENTS
          </b-td>
          <b-td v-for="index in 12" :key="index">
            <div v-if="year === currentYear">
              <span
                v-if="index != currentMonth"
                class="d-flex justify-content-center"
              >
                <money
                  v-if="index < currentMonth - 1"
                  :value="0"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none; width: auto; text-align: center"
                  :style="isDarkTheme ? 'color:white' : 'color:black'"
                  disabled
                />
                <strong
                  v-else-if="index === currentMonth - 1"
                  class="d-flex justify-content-center"
                  :style="{
                    'white-space': 'nowrap',
                    padding: '2px',
                    'font-weight': 'bolder',
                    'border-radius': '5px',
                    'background-color': '#ff9f43',
                    color: 'white',
                  }"
                >
                  Generate
                </strong>
                <strong v-else> - </strong>
              </span>
              <span
                v-else
                class="d-flex justify-content-center"
                :style="{
                  'white-space': 'nowrap',
                  padding: '2px',
                  'font-weight': 'bolder',
                  'border-radius': index === currentMonth ? '5px' : 'none',
                  'background-color':
                    index === currentMonth ? '#5e81ac' : 'transparent',
                  color: index === currentMonth ? 'white' : '',
                }"
              >
                Pending
              </span>
            </div>
            <div v-else>
              <money
                :value="0"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: 'S/ ',
                  precision: 2,
                  masked: false,
                }"
                style="border: none; width: auto; text-align: center"
                :style="isDarkTheme ? 'color:white' : 'color:black'"
                disabled
              />
            </div>
          </b-td>
          <b-td
            :key="indexCont"
            class="font-size-09 font-weight-bolder sticky-column-last"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            <span
              class="d-flex justify-content-center"
              :style="'white-space: nowrap;font-weight: bolder;background:gray;border-radius:5px;padding:5px;color:white'"
            >
              $ 0
            </span>
          </b-td>
        </b-tr>
        <b-tr
          class="background-2 font-size-09 font-weight-bolder"
          :style="'white-space: nowrap;font-weight: bolder'"
          :class="isDarkTheme ? 'background-dark' : 'background-light'"
        >
          <!-- poner payments a la primera columna -->
          <b-td
            class="background-1 font-size-09 font-weight-bolder sticky-column-first"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
            colspan="1"
          >
            CONTRIBUTIONS
          </b-td>
          <b-td v-for="index in 12" :key="index">
            <div v-if="year === currentYear">
              <span
                v-if="index != currentMonth"
                class="d-flex justify-content-center"
              >
                <money
                  v-if="index < currentMonth - 1"
                  :value="0"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none; width: auto; text-align: center"
                  :style="isDarkTheme ? 'color:white' : 'color:black'"
                  disabled
                />
                <strong
                  v-else-if="index === currentMonth - 1"
                  class="d-flex justify-content-center"
                  :style="{
                    'white-space': 'nowrap',
                    padding: '2px',
                    'font-weight': 'bolder',
                    'border-radius': '5px',
                    'background-color': '#ff9f43',
                    color: 'white',
                  }"
                >
                  Generate
                </strong>
                <strong v-else> - </strong>
              </span>
              <span
                v-else
                class="d-flex justify-content-center"
                :style="{
                  'white-space': 'nowrap',
                  padding: '2px',
                  'font-weight': 'bolder',
                  'border-radius': index === currentMonth ? '5px' : 'none',
                  'background-color':
                    index === currentMonth ? '#5e81ac' : 'transparent',
                  color: index === currentMonth ? 'white' : '',
                }"
              >
                Pending
              </span>
            </div>
            <div v-else>
              <money
                :value="0"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: 'S/ ',
                  precision: 2,
                  masked: false,
                }"
                style="border: none; width: auto; text-align: center"
                :style="isDarkTheme ? 'color:white' : 'color:black'"
                disabled
              />
            </div>
          </b-td>
          <b-td
            :key="indexCont"
            class="font-size-09 font-weight-bolder sticky-column-last"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            <span
              class="d-flex justify-content-center"
              :style="'white-space: nowrap;font-weight: bolder;background:gray;border-radius:5px;padding:5px;color:white'"
            >
              S/ 0
            </span>
          </b-td>
        </b-tr>
        <b-tr
          class="background-3 font-size-09 font-weight-bolder"
          :style="'white-space: nowrap;font-weight: bolder'"
          :class="isDarkTheme ? 'background-dark' : 'background-light'"
        >
          <!-- poner payments a la primera columna -->
          <b-td
            class="background-1 font-size-09 font-weight-bolder sticky-column-first"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
            colspan="1"
          >
            DISCOUNTS
          </b-td>
          <b-td v-for="index in 12" :key="index">
            <div v-if="year === currentYear">
              <span
                v-if="index != currentMonth"
                class="d-flex justify-content-center"
              >
                <money
                  v-if="index < currentMonth - 1"
                  :value="0"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  style="border: none; width: auto; text-align: center"
                  :style="isDarkTheme ? 'color:white' : 'color:black'"
                  disabled
                />
                <strong
                  v-else-if="index === currentMonth - 1"
                  class="d-flex justify-content-center"
                  :style="{
                    'white-space': 'nowrap',
                    padding: '2px',
                    'font-weight': 'bolder',
                    'border-radius': '5px',
                    'background-color': '#ff9f43',
                    color: 'white',
                  }"
                >
                  Generate
                </strong>
                <strong v-else> - </strong>
              </span>
              <span
                v-else
                class="d-flex justify-content-center"
                :style="{
                  'white-space': 'nowrap',
                  padding: '2px',
                  'font-weight': 'bolder',
                  'border-radius': index === currentMonth ? '5px' : 'none',
                  'background-color':
                    index === currentMonth ? '#5e81ac' : 'transparent',
                  color: index === currentMonth ? 'white' : '',
                }"
              >
                Pending
              </span>
            </div>
            <div v-else>
              <money
                :value="0"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: 'S/ ',
                  precision: 2,
                  masked: false,
                }"
                style="border: none; width: auto; text-align: center"
                :style="isDarkTheme ? 'color:white' : 'color:black'"
                disabled
              />
            </div>
          </b-td>
          <b-td
            :key="indexCont"
            class="font-size-09 font-weight-bolder sticky-column-last"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            <span
              class="d-flex justify-content-center"
              :style="'white-space: nowrap;font-weight: bolder;background:gray;border-radius:5px;padding:5px;color:white'"
            >
              S/ 0
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td
            class="font-size-09 font-weight-bolder sticky-column-first"
            :style="'white-space: nowrap;font-weight: bolder'"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
            colspan="1"
          >
            DETAILS
          </b-td>
          <b-td
            v-for="index in 12"
            :key="index"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          >
            <div class="d-flex justify-content-center">
              <div v-if="year === currentYear">
                <b-badge
                  v-if="index === currentMonth"
                  :style="
                    isDarkTheme
                      ? 'background:#2ACB57;color:white'
                      : 'background:#D7FFE2;color:#2ACB57'
                  "
                  style="cursor: pointer"
                  @click="viewDetails(index)"
                >
                  DETAILS
                </b-badge>
                <b-badge
                  v-else
                  :style="
                    isDarkTheme
                      ? 'background:#FC424A;color:white'
                      : 'background:#FFCDD0;color:#FC424A'
                  "
                >
                  NO DETAILS
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  :style="
                    isDarkTheme
                      ? 'background:#FC424A;color:white'
                      : 'background:#FFCDD0;color:#FC424A'
                  "
                >
                  NO DETAILS
                </b-badge>
              </div>
            </div>
          </b-td>
          <b-td
            class="font-size-09 font-weight-bolder sticky-column-last"
            :class="isDarkTheme ? 'background-dark' : 'background-light'"
          />
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <payroll-payments-grid
      v-if="showSidebarViewDetails"
      :tab="tab"
      :content="content"
      :month="valueFilterMonth"
      :year="valueFilterYear"
      :module="valueFilterModule"
      :name-module="valueFilterModuleName"
      @close="closeSidebarViewDetails"
    />
  </div>
</template>
<script>
import PayrollPaymentsGrid from "@/views/commons/components/payroll-payments/components/PayrollPaymentsGrid.vue";
import PayrollPaymentsService from "@/views/commons/components/payroll-payments/payroll-payments.service";
import moment from "moment";

export default {
  components: {
    PayrollPaymentsGrid,
  },
  props: {
    tab: {
      type: Number,
      default: null,
    },
    nameModule: {
      type: String,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
    module: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      reports: [],
      indexCont: 0,
      showSidebarViewDetails: false,
      content: null,
      valueFilterMonth: null,
      valueFilterYear: null,
      valueFilterModule: null,
      valueFilterModuleName: null,
      currentMonth: null,
      currentYear: null,
      monthValues: [
        "",
        "value_jan",
        "value_feb",
        "value_mar",
        "value_apr",
        "value_may",
        "value_jun",
        "value_jul",
        "value_aug",
        "value_sep",
        "value_oct",
        "value_nov",
        "value_dec",
        "value_total",
      ],
    };
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.currentMonth = parseInt(moment().format("M"), 10);
    this.currentYear = parseInt(moment().format("YYYY"), 10);
    await this.getReports();
  },
  methods: {
    closeSidebarViewDetails() {
      this.showSidebarViewDetails = false;
    },
    async getReports() {
      try {
        this.addPreloader();
        const response = await PayrollPaymentsService.getPayrollPaymentsReport({
          year: this.year != null ? this.year : moment().format("YYYY"),
          tab: this.tab,
          module_id: this.module,
        });
        if (response.status === 200) {
          this.reports = response.data;
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        if (this.isCreated) this.removePreloader();
        throw error;
      }
    },

    viewDetails(month) {
      this.content = [];
      if (this.reports.length > 0) {
        this.reports.forEach((item) => {
          this.content.push(item[this.monthValues[month]]);
        });
      } else {
        this.content.push(0);
        this.content.push(0);
        this.content.push(0);
      }

      this.showSidebarViewDetails = true;
      this.valueFilterMonth = month;
      this.valueFilterYear = parseInt(this.year, 10);
      this.valueFilterModule = this.module;
      this.valueFilterModuleName = this.nameModule;
    },
  },
};
</script>

<style>
.font-size-09 {
  font-size: 9pt !important;
}

.background-dark {
  background: #545454;
  color: white;
}
.background-light {
  background: #fcfcfc;
  color: #545454;
}
.background-dark-pending {
  background: rgba(40, 167, 70, 0.842);
  color: white;
}
.background-light-pending {
  background: rgba(40, 167, 70, 0.719) #fcfcfc;
  color: white;
}
.sticky-column {
  position: sticky;
}
.sticky-column-first {
  position: sticky;
  left: 0;
}
.sticky-column-last {
  position: sticky;
  right: 0;
}
.first-column {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.last-column {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
</style>
