<template>
  <div>
    <b-modal
      v-model="showPreviewModal"
      scrollable
      title-class="h3 text-white font-weight-bolder text-center"
      size="lg"
      :title="`Update fifth category calculation ${dataEmployee.know_as}`"
      @hidden="close"
    >
      <b-row class="d-flex align-items-center">
        <b-col>
          <p class="p-1 text-warning">
            <feather-icon icon="AlertTriangleIcon" />
            Make sure the months don't repeat
          </p>
        </b-col>
        <b-col
          class="text-right align-self-center"
        >
          <b-button
            :disabled="paymentDetails.length >= 7"
            size="sm"
            variant="primary"
            @click="addPaymentDetails()"
          >
            <feather-icon
              icon="PlusIcon"
              size="20"
            />
            Add Contribution

          </b-button>
        </b-col>
      </b-row>

      <b-col
        v-if="paymentDetails.length === 0"
        class="text-center py-2"
      >
        <h4 class="text-warning font-weight-bolder">
          Without fifth category registrations, add one registration.
        </h4>
      </b-col>

      <validation-observer ref="form">

        <b-row
          v-for=" (item, index) in paymentDetails"
          :key="index"
        >

          <b-col>
            <validation-provider
              v-slot="{ errors }"
              :name="`amount_${index}`"
              :rules="`required|max_value:99999999|min_value:0.1`"
            >
              <b-form-group
                label="Amount"
                label-for="amount"
              >
                <money
                  :id="`amount_${index}`"
                  v-model="item.amount"
                  :v-mask="'#########'"

                  :value="item.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: 'S/',
                    precision: 2,
                    maxlength: 11,
                    masked: false,
                  }"
                  class="form-control"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              :name="`payment_date_${index}`"
              rules="required"
            >
              <b-form-group
                label="Payment date"
                label-for="Payment date"
              >
                <kendo-datepicker
                  v-model="item.date_contribution"
                  v-mask="'##/##/####'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger rounded' : ''"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center">

            <template>

              <h5
                v-if="item.date_contribution"
                class="text-primary"
              >
                {{ new Date(item.date_contribution).toLocaleString('default', { month: 'long' }).toUpperCase() }}
              </h5>

              <h5
                v-else
                class="text-warning"
              >
                Without payment date
              </h5>

            </template>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center">
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer text-danger"
              size="25"
              @click="deletePaymentsIdS(item.id,index)"
            />

          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          class="d-flex justify-content-center"
          variant="success"
          @click="validatePaymentDate()"
        >
          CONFIRM
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import PayrollPaymentsService from '@/views/commons/components/payroll-payments/payroll-payments.service';
import { mapGetters } from 'vuex';

export default {

  props: {
    dataEmployee: {
      default: null,
      type: Object,
      require: true,
    },
  },
  data() {
    return {

      zeroOrOther: false,
      showPreviewModal: false,
      paymentDetails: [],
      deletedPaymentsId: [],

    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  mounted() {
    this.showPreviewModal = true;
    this.paymentDetails = JSON.parse(this.dataEmployee.contributions_fift)[0].amount ? JSON.parse(this.dataEmployee.contributions_fift) : [
      {
        id: null,
        amount: 0,
        date_contribution: null,
      },
    ];
  },

  methods: {

    addPaymentDetails() {
      if (this.paymentDetails.length < 7) {
        this.paymentDetails.push({
          id: null,
          amount: 0,
          date_contribution: null,
        });
      }
    },

    async validatePaymentDate() {
      // validate
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const seenMonths = new Set();
      let hasDuplicates = false;

      this.paymentDetails.forEach(item => {
        if (item.amount <= 0) {
          this.zeroOrOther = true;
        }
      });

      this.paymentDetails.forEach(item => {
        const parts = item.date_contribution.split('/');
        const month = parseInt(parts[0], 10);

        if (seenMonths.has(month)) {
          hasDuplicates = true;
          return;
        }

        seenMonths.add(month);
      });

      if (this.zeroOrOther) {
        this.showWarningSwal('Please enter a valid amount great than 0.00');
        return;
      }

      if (hasDuplicates) {
        // Mostrar un mensaje de error o realizar alguna acción en caso de meses repetidos.
        this.showWarningSwal('Repeated months were found');
      } else {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        // Los meses no se repiten, puedes continuar con la acción.
        this.updateFifthCategoryCalculation();
      }
    },

    deletePaymentsIdS(id, index) {
      if (id) {
        this.deletedPaymentsId.push({
          id,
        });
      }

      this.paymentDetails.splice(index, 1);
    },
    close() {
      this.showPreviewModal = false;
      this.$emit('closeModal');
    },
    async updateFifthCategoryCalculation() {
      try {
        this.addPreloader();

        const data = await PayrollPaymentsService.updateFifthCategoryCalculation({
          employee_id: this.dataEmployee.id,
          created_by: this.currentUser.user_id,
          contribution_data: this.paymentDetails,
          deleted_contribution_data: this.deletedPaymentsId,
        });

        if (data.status === 200) {
          this.showSuccessSwal('Fifth category calculation updated successfully');
          this.$emit('refresh');
          this.close();
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
        this.removePreloader();
      }
    },
  },
};
</script>
