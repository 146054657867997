<template>
  <div>
    <b-modal
      v-model="oncontrol"
      size="xl"
      no-close-on-backdrop
      title-class="text-white h3 font-weight-bolder"
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        Payroll Payments Generated ( {{ monthName }} )
      </template>
      <filter-slot
        :filter="filteredFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refreshTable()"
      >
        <template #buttons>
          <b-button
            variant="success"
            class="text-black ml-1"
            @click="sendPayrollToEmployees"
          >
            <feather-icon icon="SendIcon" />
            Send Payroll
          </b-button>
        </template>
        <b-table
          slot="table"
          ref="refTableSendEmail"
          no-provider-filtering
          :items="getEmployees"
          :fields="filteredFields"
          primary-key="id"
          table-class="text-nowrap fixed-header tb-payroll"
          show-empty
          sticky-header="50vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
        >
          <template #head(checked)>
            <b-form-checkbox
              v-model="allEmployeesAreSelected"
              @change="selectAllEmployees"
          /></template>
          <template #cell(checked)="data">
            <b-form-checkbox
              v-if="data.item.file_route && data.item.is_sended == 0"
              v-model="employeesSelectedForEmail"
              :value="data.item"
            />
          </template>
          <template #cell(first_name)="{ item }">
            <div>
              <span class="font-weight-bolder"> Name: </span>
              <span class="text-primary">{{
                convertCapitalize(`${item.first_name} ${item.last_name}`)
              }}</span>
            </div>

            <div>
              <span class="font-weight-bolder"> Know As: </span>
              <span class="text-secondary">{{
                convertCapitalize(item.know_as)
              }}</span>
            </div>
            <div v-if="item.pid">
              <b-badge variant="primary">
                {{ item.pid }}
              </b-badge>
            </div>
          </template>
          <template #cell(department)="{ item }">
            {{ renameModule(item.department) }}
          </template>

          <!-- contributions_fift -->
          <template #cell(update_fifth)="data">
            <b-row>
              <b-col>
                <b-card
                  class="mb-0"
                  :class="isDarkSkin ? 'card-dark' : 'card-light'"
                >
                  <b-row
                    v-for="(item, index) in JSON.parse(
                      data.item.contributions_fift
                    )"
                    :key="index"
                  >
                    <b-col class="text-right" cols="6">
                      <p v-if="item.amount" class="font-weight-bold">
                        {{ formatAmount(item.amount) }}
                      </p>
                    </b-col>

                    <b-col class="text-left" cols="6">
                      <b-badge v-if="item.amount" variant="primary">
                        {{ item.month_name }}
                      </b-badge>
                    </b-col>
                  </b-row>

                  <hr
                    v-if="sumAmounts(JSON.parse(data.item.contributions_fift))"
                  />

                  <template>
                    <b-row>
                      <b-col
                        cols="6"
                        class="text-center align-items-center"
                        v-if="
                          sumAmounts(JSON.parse(data.item.contributions_fift))
                        "
                      >
                        <h4 class="font-weight-bold" style="color: #000000">
                          <b-badge
                            :style="
                              isDarkSkin
                                ? 'background: #99e8a4; color: #000000'
                                : 'background: #99e8a4; color: #000000'
                            "
                            variant="success"
                          >
                            {{
                              formatAmount(
                                sumAmounts(
                                  JSON.parse(data.item.contributions_fift)
                                )
                              )
                            }}
                          </b-badge>
                        </h4>
                      </b-col>
                      <h5 class="text-center w-full" v-else>
                        <b-badge variant="danger">
                          Insert fifth category amount
                        </b-badge>
                      </h5>
                      <b-col
                        v-if="
                          sumAmounts(JSON.parse(data.item.contributions_fift))
                        "
                        class="mb-1 d-flex justify-content-end"
                        cols="6"
                      >
                        <a :id="`${data.item.id}_tooltip_update`">
                          <feather-icon
                            icon="EditIcon"
                            size="18"
                            @click="updateFifthCategory(data.item)"
                          />
                        </a>

                        <b-tooltip
                          variant="success"
                          triggers="hover"
                          :target="`${data.item.id}_tooltip_update`"
                          title="Update fifth category"
                          :delay="{ show: 100, hide: 50 }"
                          placement="left"
                        />
                      </b-col>

                      <b-col v-else cols="12">
                        <a :id="`${data.item.id}_tooltip_update`">
                          <feather-icon
                            icon="PlusSquareIcon"
                            size="18"
                            @click="updateFifthCategory(data.item)"
                          />
                        </a>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </b-col>
            </b-row>
          </template>
          <template #cell(actions)="data">
            <div>
              <b-dropdown
                :ref="`dropdown_${data.item.id}`"
                text="Split Dropdown"
                variant="link"
                no-caret
                toggle-class="text-decoration-none"
              >
                <template #button-content>
                  <tabler-icon
                    :id="`${data.item.id}_actions_tooltip`"
                    size="20"
                    icon="FoldersIcon"
                    class="text-primary cursor-pointer"
                    @click="openDropdown(data.item.id)"
                  />
                  Show actions
                  <b-tooltip
                    variant="primary"
                    triggers="hover"
                    :target="`${data.item.id}_actions_tooltip`"
                    title="Download files"
                    :delay="{ show: 100, hide: 10 }"
                    placement="left"
                  />
                </template>
                <b-dropdown-header
                  id="dropdown-header-label"
                  style="font-family: Verdana, sans-serif"
                >
                  {{
                    `Donwload files from ${data.item.first_name} ${data.item.last_name}`
                  }}
                </b-dropdown-header>
                <!-- Download File -->
                <b-dropdown-item
                  v-if="data.item.file_route"
                  @click="downloadEmployeeExcelDetails(data.item)"
                >
                  <tabler-icon
                    :id="`${data.item.id}_employee_tooltip`"
                    size="18"
                    icon="CurrencyDollarIcon"
                    class="text-primary mr-1"
                    style="cursor: pointer"
                  />

                  <b-tooltip
                    variant="primary"
                    triggers="hover"
                    :target="`${data.item.id}_employee_tooltip`"
                    title="Download employee detail excel"
                    :delay="{ show: 100, hide: 10 }"
                    placement="right"
                  />
                  <span
                    class="text-primary"
                    style="font-family: Verdana, sans-serif"
                    >Download Employee Detail</span
                  >
                </b-dropdown-item>
                <b-dropdown-divider />

                <b-dropdown-item
                  :href="data.item.file_route ? data.item.file_route : null"
                  target="_blank"
                  @click="
                    !data.item.file_route
                      ? generatePDFPayrollPayment(data.item, false)
                      : null
                  "
                >
                  <a
                    v-if="data.item.file_route"
                    :id="`${data.item.id}_tooltip`"
                    target="_blank"
                  >
                    <tabler-icon
                      size="18"
                      icon="FileDownloadIcon"
                      class="text-success mr-1"
                    />
                  </a>
                  <tabler-icon
                    v-else
                    :id="`${data.item.id}_tooltip`"
                    size="20"
                    icon="FileDownloadIcon"
                    class="text-danger cursor-pointer mr-1"
                  />
                  <b-tooltip
                    :variant="data.item.file_route ? 'success' : 'danger'"
                    triggers="hover"
                    :target="`${data.item.id}_tooltip`"
                    :title="
                      data.item.file_route
                        ? 'Download payroll'
                        : 'Generate payroll'
                    "
                    :delay="{ show: 100, hide: 10 }"
                    placement="left"
                  />
                  <span
                    :class="
                      data.item.file_route ? 'text-success' : 'text-danger'
                    "
                    style="font-family: Verdana, sans-serif"
                  >
                    {{
                      data.item.file_route
                        ? "Download payroll"
                        : "Generate payroll"
                    }}</span
                  >
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.file_approved_route"
                  :href="data.item.file_approved_route"
                >
                  <a
                    v-if="data.item.file_approved_route"
                    :id="`${data.item.number}_approved_tooltip`"
                    target="_blank"
                  >
                    <tabler-icon
                      size="18"
                      icon="FileCheckIcon"
                      class="text-primary cursor-pointer mr-1"
                    />
                  </a>
                  <b-tooltip
                    v-if="data.item.file_approved_route"
                    variant="primary"
                    triggers="hover"
                    :target="`${data.item.number}_approved_tooltip`"
                    :title="`Download approved payroll`"
                    :delay="{ show: 100, hide: 50 }"
                    placement="right"
                  />
                  <span
                    :class="
                      data.item.file_approved_route
                        ? 'text-primary'
                        : 'text-danger'
                    "
                    style="font-family: Verdana, sans-serif"
                  >
                    {{
                      data.item.file_route
                        ? "Download Approved payroll"
                        : "Generate payroll"
                    }}</span
                  >
                </b-dropdown-item>
                <b-dropdown-divider />

                <!-- Regenarate payroll -->
                <b-dropdown-item
                  v-if="data.item.file_route"
                  @click="generatePDFPayrollPayment(data.item, true)"
                >
                  <feather-icon
                    v-if="data.item.file_route"
                    :id="`${data.item.number}_regenerate_tooltip`"
                    size="18"
                    icon="RefreshCcwIcon"
                    class="text-primary cursor-pointer mr-1"
                  />
                  <b-tooltip
                    variant="primary"
                    triggers="hover"
                    :target="`${data.item.number}_regenerate_tooltip`"
                    :title="`Regenerate payroll`"
                    :delay="{ show: 100, hide: 10 }"
                    placement="left"
                  />
                  <span
                    class="text-primary"
                    style="font-family: Verdana, sans-serif"
                    >Regenerate Payroll</span
                  >
                </b-dropdown-item>
                <!-- Generate payroll -->
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </filter-slot>
      <modal-update-fifth-category
        v-if="modalUpdateFifthOwn"
        :data-employee="fifthCategoryData"
        @closeModal="modalUpdateFifthOwn = false"
        @refresh="refreshTable"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import HelpDeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import PayrollPaymentsService from "@/views/commons/components/payroll-payments/payroll-payments.service";
import ModalUpdateFifthCategory from "@/views/commons/components/payroll-payments/modals/ModalUpdateFifthCategory.vue";

export default {
  components: {
    ModalUpdateFifthCategory,
  },
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    monthGeneral: {
      type: Number,
      default: null,
    },
    yearSelect: {
      type: Number,
      default: null,
    },
    monthName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fieldsSentEmails: [
        {
          key: "email_used",
          label: "Email",
          visible: true,
        },
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
      ],
      detailsEmail: [],
      employeeDetail: null,
      showModalDetailsEmail: false,
      showPreviewModal: false,
      showModalApprovedPayrollPayments: false,
      employees: [],
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      oncontrol: false,
      filteredFilters: [
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Module",
          model: null,
          options: [],
          reduce: "id",
          selectText: "name",
          cols: 12,
          visible: false,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Status",
          model: null,
          options: [
            {
              id: 1,
              name: "Checked",
            },
            {
              id: 0,
              name: "Not Checked",
            },
          ],
          reduce: "id",
          selectText: "name",
          cols: 12,
          visible: false,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Company",
          model: null,
          options: [],
          reduce: "id",
          selectText: "display_name_as",
          cols: 12,
          visible: true,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Department",
          model: null,
          options: [],
          reduce: "id",
          selectText: "name",
          cols: 12,
          visible: true,
        },
        {
          type: "select",
          margin: true,
          showLabel: true,
          label: "Status",
          model: null,
          options: [
            {
              id: 1,
              name: "Approved",
            },
            {
              id: 0,
              name: "Not Approved Yet",
            },
          ],
          reduce: "id",
          selectText: "name",
          cols: 12,
          visible: true,
        },
      ],
      filteredFields: [
        {
          key: "checked",
          sortable: false,
          label: "Checked",
        },
        {
          key: "first_name",
          sortable: false,
          label: "Name",
        },
        {
          key: "department",
          sortable: false,
          label: "Department",
        },
        {
          key: "display_name_as",
          sortable: false,
          label: "Company",
        },
        {
          key: "update_fifth",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: "width: 300px",
          label: "Fifth Category",
        },
        {
          key: "actions",
          sortable: false,
          label: `Payroll (${
            this.monthGeneral
              ? moment(this.monthGeneral, "MM").format("MMMM")
              : moment().subtract(1, "month").format("MMMM")
          })`,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      paymentsApproved: [],
      isBusy: false,
      sortBy: "payment_date",
      sortDesc: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      searchInput: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        label: "Search",
        placeholder: "Search ...",
        model: null,
      },
      allEmployeesAreSelected: false,
      employeesSelectedForEmail: [],
      payrollId: null,
      modalUpdateFifthOwn: false,
      fifthCategoryData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    thereAreEmployeesSelected() {
      return this.employeesSelectedForEmail.length > 0;
    },
    countEmployeesSelected() {
      return this.employeesSelectedForEmail.length > 0
        ? `(${this.employeesSelectedForEmail.length})`
        : "";
    },
  },
  watch: {
    showPreviewModal(newValue) {
      if (newValue === false) {
        this.employeesSelectedForEmailSimplified = [];
        return;
      }
      this.employeesSelectedForEmailSimplified =
        this.employeesSelectedForEmail.map((employee) => ({
          id: employee.id,
          pid: employee.pid,
          full_name: `${employee.first_name.split(" ")[0]} ${
            employee.last_name.split(" ")[0]
          }`,
          private_email: employee.private_email,
          department: employee.department,
          payroll_payment_id: employee.payroll_payment_id,
        }));
    },
  },
  mounted() {
    this.oncontrol = true;
    this.getModules();
    this.getActiveCompanies();
    this.getDepartments();
  },
  methods: {
    formatAmount(value) {
      // Redondea el valor a 2 decimales y conviértelo a cadena con 2 decimales fijos
      // return parseFloat(value).toFixed(2);
      const prefix = "S/";
      const formattedValue = parseFloat(value).toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return `${prefix}${formattedValue}`;
    },

    // suma de los amounts de los contributions pasados por parametro
    sumAmounts(contributions) {
      let sum = 0;
      contributions.forEach((element) => {
        sum += element.amount;
      });
      return sum;
    },
    updateFifthCategory(data) {
      this.modalUpdateFifthOwn = true;
      this.fifthCategoryData = data;
    },
    closeShowDetails() {
      this.showModalDetailsEmail = false;
      this.detailsEmail = [];
      this.employeeDetail = null;
    },
    showDetails(item, name, lastName) {
      this.detailsEmail = JSON.parse(item);
      this.showModalDetailsEmail = true;
      this.employeeDetail = `${name} ${lastName}`;
    },
    closePreviewModal() {
      this.showPreviewModal = false;
      this.oncontrol = false;
    },

    selectAllEmployees() {
      this.employeesSelectedForEmail = this.allEmployeesAreSelected
        ? this.employees
            .slice()
            .filter(
              (employee) => employee.file_route && employee.is_sended === 0
            )
        : [];
    },
    closeModal() {
      this.oncontrol = false;
      this.$emit("closeModal");
    },
    refreshTable() {
      this.allEmployeesAreSelected = false;
      this.employeesSelectedForQr = [];
      this.$refs.refTableSendEmail.refresh();
    },
    async getActiveCompanies() {
      try {
        this.addPreloader();
        const data = await PayrollPaymentsService.getActiveCompanies();
        if (data.status === 200) {
          this.filteredFilters[2].options = data.data;
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        console.log("error getActiveCompanies", error);
      }
    },
    async getDepartments() {
      try {
        this.addPreloader();
        const data = await PayrollPaymentsService.getDepartments();
        if (data.status === 200) {
          this.filteredFilters[3].options = data.data;
        }
      } catch (error) {
        console.log("error getDepartments", error);
      }
    },
    async sendPayrollToEmployees() {
      if (!this.employeesSelectedForEmail.length) {
        this.showInfoSwal("You must select at least one employee");
        return;
      }
      const response = await this.showConfirmSwal(
        "Are you sure you want to send the payroll to the employees?"
      );
      if (!response.isConfirmed) return;
      const params = {
        employeesSelectedForEmail: this.employeesSelectedForEmail,
      };

      this.addPreloader();
      const data = await PayrollPaymentsService.sendPayrollToEmployees(params);
      if (data.status === 200) {
        this.removePreloader();
        this.showSuccessSwal("The payroll was sent successfully");
        this.refreshTable();
      }
    },
    async getEmployees() {
      try {
        this.isBusy = true;
        const previousMonth = parseInt(
          moment().subtract(1, "months").format("MM"),
          10
        );
        const params = {
          perPage: parseInt(this.paginate.perPage, 10),
          page: this.paginate.currentPage,
          text: this.filterPrincipal.model,
          departament: this.filteredFilters[0].model,
          status: this.filteredFilters[1].model,
          month: this.monthGeneral ? this.monthGeneral : previousMonth,
          year: this.yearSelect,
          company_id: this.companyId
            ? this.companyId
            : this.filteredFilters[2].model,
          department_id: this.filteredFilters[3].model,
          status_id: this.filteredFilters[4].model,
        };
        const { data } = await PayrollPaymentsService.listEmployees(params);

        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page || 0;
        this.paginate.perPage = data.per_page || 0;
        this.isBusy = false;

        this.employees = data.data || [];
        this.employeesSelectedForEmail = [];
        return this.employees;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        console.log(error);
      }
      return [];
    },
    async getModules() {
      try {
        const { data } = await HelpDeskService.getModules();
        this.filteredFilters[0].options = data;
        this.filteredFilters[0].options.unshift({ name: "All", id: null });
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
      return [];
    },

    async generatePDFPayrollPayment(
      {
        payroll_payment_id,
        month,
        year,
        first_name,
        last_name,
        id_user,
        file_route,
      },
      regenerate
    ) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();

        // Helper function to download a blob
        const downloadBlob = (blob, fileName) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        };
        let monthG = this.monthGeneral;
        let yearG = this.yearSelect;
        if (monthG == 12) {
          monthG = 1;
          yearG = yearG + 1;
        } else {
          monthG = monthG + 1;
        }

        const response = await PayrollPaymentsService.getPdfPayrollPayment({
          regenerate,
          created_by: this.currentUser.user_id,
          user_id: id_user,
          payroll_id: file_route ? payroll_payment_id : null,
          month: monthG,
          year: yearG,
        });
        const fileName = `BOLETA_DE_PAGO_${first_name}_${last_name}_${month}_${year}.pdf`;

        // Convert the response data to a blob and download it
        const blob = new Blob([response.data], { type: "application/pdf" });
        downloadBlob(blob, fileName);

        this.removePreloader();
        this.refreshTable();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    openApprovePayrollPayments(item) {
      this.payrollId = item;
      this.showModalApprovedPayrollPayments = true;
    },
    async downloadEmployeeExcelDetails(item) {
      try {
        const namefile = `PayrollDetail_${item.first_name}_${
          item.last_name
        }_${moment().format("YYYY_MM_DD_HH_MM_SS")}_.xlsx`;
        const params = {
          page: null,
          perPage: null,
          sortBy: null,
          name_text: null,
          sortOrder: null,
          filterMonth: this.monthGeneral
            ? this.monthGeneral
            : moment().format("MM") - 1,
          module_id: null,
          filterYear: this.yearSelect,
          is_excel: 1,
          name_file: namefile,
          tab: item.company_id,
          user_id: item.id_user,
        };
        const data = await PayrollPaymentsService.generateDetailPayrollExcel(
          params
        );

        await this.forceFileDownload(data.data, namefile);
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error, "error to download excel");
      }
    },
    openDropdown(id) {
      this.$refs[`dropdown_${id}`].show();
    },
    convertCapitalize(name) {
      name = name || "";
      let lowercase = name.toLowerCase();
      return lowercase
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    renameModule(moduleName) {
      const names = {
        "CUSTOMER SERVICE DIGITAL": "C.S. DIGITAL",
        "CUSTOMER SERVICE REGULAR": "C.S. REGULAR",
      };
      return names[moduleName] || moduleName;
    },
  },
};
</script>
<style scoped>
.fixed-header thead {
  position: fixed;
  top: 0;
  background-color: white; /* Cambia el color de fondo según tus preferencias */
  z-index: 1; /* Asegura que los encabezados estén por encima del contenido desplazado */
}
.card-light {
  background: #edf3fd;
}
.card-dark {
  background: #1c212b;
}
</style>

<style>
.tb-payroll {
  min-height: 200px !important;
}
</style>